export default {
    ACCESS_SITE: 'Accept and access the website',
    CHANGE_PASSWORD_TITLE: 'Type your current password and the new one.',
    CHANGE_PASSWORD: 'Change password',
    CONFIRM_PASSWORD: 'Confirm new password',
    CURRENT_PASSWORD: 'Current password',
    DISCLAIMER: 'Downloaded content shall be used within the terms and conditions of the audiovisual rights acquired.',
    DOWNLOAD: 'download',
    DOWNLOAD_CANCELED: 'Download canceled.',
    DOWNLOAD_COMPLETED: 'Download completed.',
    ERRORS: {
        CHANGE_PASSWORD: 'Unable to change your passsword.',
        LOGIN: 'Credentials not valid.',
        RESET_PASSWORD: 'Unable to reset your passsword.'
    },
    EXPORT_XLS: 'Excel export',
    CONDITIONS: {
        TITLE: 'Please accept the following conditions.',
        BANNER_DISCLAIMER: `By accessing the Platform, you confirm that you are authorised by virtue of a contract signed by
        you and/or your employer with Lega Nazionale Professionisti Serie A ("LNPA") and/or an
        Intermediary Agency, licensee of the Audiovisual Rights belonging to LNPA and/or one of the
        clubs affiliated with the F.I.G.C. and participating in the Serie A Championship (respectively,
        “Contract”, “Intermediary Agency” and “Associated Clubs”) and you acknowledge that your
        rights to access and use the Platform are regulated and limited by the Contract. By accessing, you
        further acknowledge that you understand that the media and content available on the Platform are
        protected by industrial and intellectual property rights owned by LNPA and the Associated Clubs,
        and you agree not to violate such rights in any way and to use the media and content available
        therein in accordance with and within the terms of the Contract.`,
        PRIVACY_INFORMATION: 'Read the privacy information notice (mandatory)',
        PRIVACY_INFORMATION_LINK: '/Privacy_Information_Notice_EN.pdf',
        WEBSITE_PRIVACY_POLICY: 'Website privacy policy',
        WEBSITE_PRIVACY_POLICY_LINK: '/Website_Privacy_policy_Media_Portal_EN.pdf',
        COOKIE_POLICY: 'Cookie policy',
        COOKIE_POLICY_LINK: '/Cookie_policy_Media_Portal_EN.pdf'
    },
    SELECT_CONTENT: 'Select content',
    LENGTH: 'length',
    LISTING: 'Listing',
    LOGIN_TITLE: 'Media Portal',
    LOGIN_SUBTITLE: 'Serie A League',
    LOGIN: 'Login',
    LOGOUT: 'Logout',
    HOME: 'Home',
    SELECT_LANGUAGE: 'Your language',
    GROUP_TITLES: {
        matches: 'Full Matches',
        highlights90s: 'Highlights 90 sec.',
        highlights03_it: 'Highlights 3 min. (Italian)',
        highlights03_en: 'Highlights 3 min. (English)',
        highlights05_en: 'Highlights 5 min. (English)',
        highlights20_it: 'Highlights 20 min. (Italian)',
        highlights20_en: 'Highlights 20 min. (English)',
        conferences: 'Press Conferences',
        promos: 'Promo Trailers',
        magazines: 'Magazines',
        preMatches: 'Pre-match',
        teamsArrival: 'Teams Arrival',
        webspace: 'Flex',
        extraContent: 'Extra content',
        meltReel: 'Melt Reel',
        archiveContent: 'Archive'
    },
    NEW_PASSWORD: 'New password',
    NOTES: 'Notes',
    PASSWORD: 'Password',
    //PASSWORD_PLACEHOLDER: 'Type your password',
    PROFILE: 'Profile',
    RELOAD_LISTING: 'Refresh',
    REPORT: 'Report',
    RESET_PASSWORD_TITLE: 'Type your email address to reset your password.',
    RESET_PASSWORD: 'reset password',
    PURGE_CACHE: 'Empty cache',
    SEARCH_TEXT: 'Type for filter...',
    SEARCH_TEXT_FOCUS: '',
    SEARCH_TEXT_PLACEHOLDER: 'Type for filter...',
    SUCCESS: {
        CHANGE_PASSWORD: 'Password changed successfully.',
        LOGIN: 'Login done successfully.',
        RESET_PASSWORD: 'Password reset successfully.',
        PURGE_CACHE: 'The cache has been emptied.'
    },
    USERNAME: 'Username',
    USERINFO: 'Info',
    //USERNAME_PLACEHOLDER: 'Username',
    CPITA: 'Coppa Italia',
    SERIEA: 'Serie A',
    SCITA: 'Supercup',
    FROM_DATE: 'From',
    TO_DATE: 'To',
    GET_REPORT: 'Get report',
    REPORT_COLUMNS: {
        DATE: 'Date',
        FILE_NAME: 'File name',
        PROD_STD: 'Prod std',
        USER_NAME: 'Nome utente',
        USER_ID: 'User id',
        COUNTER: 'N. Downloads',
        PROD_VALUE: 'Prod value'
    },
    GENERIC_ERROR: 'An error occured.',
    NO_CONTENT: 'No available content',
    PROFILE_LABELS: {
        CATEGORY: 'Category',
        CATEGORY_NAME: 'Category Name',
        CHAMPIONSHIPS: 'Championships',
        CONTENTTYPES: 'ContentTypes',
        EXCLUSIVE_INCLUSION_MATCHES: 'Exclusive Inclusion Matches',
        TEAMS: 'Teams'
    },
    USER_MANUAL: {
        LINK: '/LNPA_Media_Portale_user_manual_ENG_2023-24.pdf'
    }
};