interface IGenerateThunkParams<T, U> {
    errorMessage?: string;
    fn: (payload: T) => Promise<U>;
    payload?: T;
    thunkAPI?: any
}

export function timeout(seconds = 1) {
    return new Promise(resolve => setTimeout(resolve, seconds * 1000));
}

export const validations = {
    email: function (email: string) {
        // eslint-disable-next-line max-len
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
};

export async function generateThunk<T, U>({ fn, errorMessage, payload = {} as T, thunkAPI }: IGenerateThunkParams<T, U>): Promise<U> {
    try {
        const response = await fn(payload);

        if (!response) {
            return thunkAPI.rejectWithValue(errorMessage);
        }

        return response;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(errorMessage);
    }
}

export function parseJwt(token: string) {
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(ch) {
            return '%' + ('00' + ch.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }
    catch (e) {
        console.error(e);
        return null;
    }
}

export function jwtIsValid(token: string) {
    if (!token) {
        return false;
    }

    try {
        const jwt = parseJwt(token);

        if (Date.now() >= jwt.exp * 1000) {
            return false;
        }
    }
    catch (e) {
        console.error(e);
        return false;
    }

    return true;
}

export function tableToCsv(tableId:string, fileName?: string, separator = ',') {
    const rows = document.querySelectorAll('table#' + tableId + ' tr');
    const csv = [];
    for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
        const row = [], cols = rows[rowIndex].querySelectorAll('td, th');
        for (let colIndex = 0; colIndex < cols.length; colIndex++) {
            // @ts-ignore
            let data = cols[colIndex].innerText.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ');
            data = data.replace(/"/g, '""');
            row.push('"' + data + '"');
        }
        csv.push(row.join(separator));
    }
    const csv_string = csv.join('\n');

    const fullFilename = (fileName || 'export_' + tableId + '_' + new Date().toLocaleDateString()) + '.csv';
    const link = document.createElement('a');
    link.style.display = 'none';
    link.setAttribute('target', '_blank');
    link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string));
    link.setAttribute('download', fullFilename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function getFilename(url: string) {
    if (!url) {
        return '';
    }

    const part = url.toString().match(/.*\/(.+?)\./);
    if (part && part.length > 1)
    {
        return part[1];
    }
}

export function capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
