import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';

import { parseJwt } from '@/shared/utils';
import { setProfile } from '@/state/user';

function useLogin() {
    const dispatch = useDispatch();
    const [{ token }] = useCookies(['token']);

    useEffect(function() {
        if (!token) {
            return;
        }

        try {
            const user = parseJwt(token);
            dispatch(setProfile(user));
        }
        catch (err) {
            // empty on purpose.
        }

    }, [token]);
}

export default useLogin;