import SlideshowImage01 from '../../images/slideshow/slideshow-01.jpg';
import SlideshowImage02 from '../../images/slideshow/slideshow-02.jpg';
import SlideshowImage03 from '../../images/slideshow/slideshow-03.jpg';
import SlideshowImage04 from '../../images/slideshow/slideshow-04.jpg';
import SlideshowImage05 from '../../images/slideshow/slideshow-05.jpg';
import SlideshowImage06 from '../../images/slideshow/slideshow-06.jpg';
import SlideshowImage07 from '../../images/slideshow/slideshow-07.jpg';
import SlideshowImage08 from '../../images/slideshow/slideshow-08.jpg';
import SlideshowImage09 from '../../images/slideshow/slideshow-09.jpg';
import SlideshowImage10 from '../../images/slideshow/slideshow-10.jpg';

export const SlideshowList = [
    SlideshowImage01,
    SlideshowImage02,
    SlideshowImage03,
    SlideshowImage04,
    SlideshowImage05,
    SlideshowImage06,
    SlideshowImage07,
    SlideshowImage08,
    SlideshowImage09,
    SlideshowImage10
];
