import React, { FunctionComponent, useLayoutEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Redirect, Route } from 'react-router-dom';

import { CHAMPIONSHIPS, ChampionshipsValues, ROUTES, THEMES } from '@/shared/constants';
import { jwtIsValid } from '@/shared/utils';

interface Props {
    component: FunctionComponent;
    path: string | readonly string[];
    restricted?: boolean;
    theme?: ChampionshipsValues | string;
}

function RestrictedRoute({ component, path, restricted = true, theme, ...rest }: Props) {
    const [{ token }] = useCookies(['token']);

    useLayoutEffect(function() {
        document.body.classList.remove(...Object.values(THEMES));

        if (theme !== undefined) {
            document.body.classList.add(theme);
        }

        return function() {
            document.body.classList.remove(...Object.values(CHAMPIONSHIPS));
        };
    }, [theme]);

    function routeComponent(props: any) {
        if (restricted === false) {
            return React.createElement(component, props);
        } else {
            return jwtIsValid(token) ? React.createElement(component, props) : <Redirect to={{ pathname: ROUTES.LOGIN }} />;
        }
    }

    return <Route {...rest} render={routeComponent}/>;
}

export default RestrictedRoute;