import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ROUTES } from '@/shared/constants';
import { logout } from '@/state/user';

function Logout() {
    const [{ token }, , removeCookie] = useCookies(['token']);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(function() {
        dispatch(logout());

        removeCookie('token');
        history.replace(ROUTES.LOGIN);
    }, [token]);

    return <div>Logout in progress ...</div>;
}

export default Logout;
