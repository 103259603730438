export const TOASTER_TIMEOUT_SECONDS = 5;

export const UTENTE_ADMIN_INTERNO = {
    id: '703',
    name: 'UTENTE ADMIN INTERNO'
};

export const CHAMPIONSHIPS = {
    SERIE_A: 'a',
    COPPA_ITALIA: 'cpita',
    SUPER_COPPA: 'scita'
} as const;

export const USER_TYPE = {
    LEGA: 'LEGA',
    CLUB: 'CLUB'
} as const;

export type ChampionshipsValues = typeof CHAMPIONSHIPS[keyof typeof CHAMPIONSHIPS];

const listing = '/listing';

export const CATEGORIES = {
    WEBSPACE: 'webspace'
};

export const ROUTES = {
    //CHANGE_PASSWORD: '/change-password',
    HOME: '/',
    CONTENT_SELECT: '/content-select',
    LISTING_SERIE_A: `${listing}/${CHAMPIONSHIPS.SERIE_A}`,
    LISTING_COPPA_ITALIA: `${listing}/${CHAMPIONSHIPS.COPPA_ITALIA}`,
    LISTING_SUPER_COPPA: `${listing}/${CHAMPIONSHIPS.SUPER_COPPA}`,
    LOGIN: '/login',
    LOGOUT: '/logout',
    PROFILE: '/profile',
    //RESET_PASSWORD: '/reset-password',
    REPORT: '/report'
};

export const COOKIES = {
    HAS_SEEN_DISCLAIMER: 'hasSeenDisclaimer',
    HAS_SEEN_PRIVACY_INFORMATION: 'hasSeenPrivacyInformation',
    LANGUAGE: 'LANG',
    USER_TOKEN: 'token'
};

export const MEDIA  = {
    THUMBNAIL_EXTENSION: 'jpg',
    VIDEO_EXTENSION: 'mp4',
    MAGAZINE_EXTENSION: 'mxf'
};

export const THEMES = {
    CONTENT_SELECT: 'nobackground',
    LISTING_SERIE_A: CHAMPIONSHIPS.SERIE_A,
    LISTING_COPPA_ITALIA: CHAMPIONSHIPS.COPPA_ITALIA,
    LISTING_SUPER_COPPA: CHAMPIONSHIPS.SUPER_COPPA,
    PROFILE: 'default',
    REPORT: 'default',
    LOGIN: 'background',
    LOGOUT: 'background'
};